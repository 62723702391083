import { Image } from '@/components/Image';
import { LabradorComponent } from '@/types/component';
import { mapLabradorImageToStandaloneImage } from '@/utils/image';

export const HantLabradorImage: LabradorComponent = ({ type, data, meta, descendants }) => {
  const imageData = mapLabradorImageToStandaloneImage({ type, data, meta, children: descendants });

  if (!data.showCaption) {
    imageData.byline = undefined;
  }

  return <Image {...imageData} />;
};
